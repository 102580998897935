import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const DatenschutzPage = () => (
  <Layout>
    <SEO title="Impressum" keywords={[`gatsby`, `application`, `react`]} />
    <div className="container margin-80-top">
      <div className="row">
        <div className="col-12">
          <h1>Datenschutzhinweise</h1>
          <p className="font-weight-bold margin-40-top">
            1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
          </p>
          <p>
            Diese Datenschutz-Information gilt für die Datenverarbeitung durch:
          </p>
          <p>
            Verantwortlicher:
            <br />
            Kanz & Sagolla GbR (K & S Rechtsanwälte)
            <br />
            Dresdener Straße 11
            <br />
            65474 Bischofsheim
            <br />
            info@ksrechtsanwaelte.de
            <br />
            Telefon +49 (0) 06144 / 4029610
            <br />
            Telefax +49 (0) 06144 / 4029619
          </p>
          <p>
            Vertretungsberechtigte Gesellschafter (Partner):
            <br />
            Rechtsanwalt Simon Kanz
            <br />
            Rechtsanwältin Beatrice Sagolla
            <br />
            (im Folgenden Rechtsanwälte)
            <br />
          </p>
          <p className="font-weight-bold">
            2. Erhebung und Speicherung personenbezogener Daten sowie Art und
            Zweck von deren Verwendung
          </p>
          <p>a) Beim Besuch der Website</p>
          <p>
            Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät
            zum Einsatz kommenden Browser automatisch Informationen an den
            Server unserer Website gesendet. Diese Informationen werden temporär
            in einem sog. Logfile gespeichert. Folgende Informationen werden
            dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung
            gespeichert:
          </p>
          <p>
            IP-Adresse des anfragenden Rechners,
            <br />
            Datum und Uhrzeit des Zugriffs,
            <br />
            Name und URL der abgerufenen Datei,
            <br />
            Website, von der aus der Zugriff erfolgt (Referrer-URL),
            <br />
            verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie
            der Name Ihres Access-Providers.
            <br />
          </p>
          <p>
            Die genannten Daten werden durch uns zu folgenden Zwecken
            verarbeitet:
          </p>
          <p>
            Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,
            <br />
            Gewährleistung einer komfortablen Nutzung unserer Website,
            <br />
            Auswertung der Systemsicherheit und -stabilität sowie
            <br />
            zu weiteren administrativen Zwecken.
            <br />
          </p>
          <p>
            Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1
            lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben
            aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden
            wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person
            zu ziehen. Darüber hinaus setzen wir beim Besuch unserer Website
            Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten
            Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung.
          </p>
          <p>b) Bei Nutzung unseres Kontaktformulars</p>
          <p>
            Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns
            über ein auf der Website bereitgestelltes Formular Kontakt
            aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse
            erforderlich, damit wir wissen, von wem die Anfrage stammt und um
            diese beantworten zu können. Weitere Angaben können freiwillig
            getätigt werden. Die Datenverarbeitung zum Zwecke der
            Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
            auf Grundlage Ihrer freiwillig erteilten Einwilligung. Die für die
            Benutzung des Kontaktformulars von uns erhobenen personenbezogenen
            Daten werden nach Erledigung der von Ihnen gestellten Anfrage
            automatisch gelöscht.
          </p>
          <p className="font-weight-bold">3. Weitergabe von Daten</p>
          <p>
            Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als
            den im Folgenden aufgeführten Zwecken findet nicht statt.
            <br />
            Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
          </p>
          <p>
            Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche
            Einwilligung dazu erteilt haben, die Weitergabe nach Art. 6 Abs. 1
            S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen erforderlich ist und kein Grund zur Annahme
            besteht, dass Sie ein berwiegendes schutzwürdiges Interesse an der
            Nichtweitergabe Ihrer Daten haben, für den Fall, dass für die
            Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche
            Verpflichtung besteht, sowie dies gesetzlich zulässig und nach Art.
            6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung
          </p>
          <p className="font-weight-bold">4. Externe Dienste</p>
          <p>a) Google Web Fonts</p>
          <p>
            Diese Website benutzt zur einheitlichen Darstellung von Schriftarten
            so genannte Web Fonts, die von Google bereitgestellt werden. Beim
            Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
            Ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
          </p>
          <p>
            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
            den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
            darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
            Die Nutzung von Google Web Fonts erfolgt im Interesse einer
            einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
            Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1
            lit. f DSGVO dar.
          </p>
          <p>
            Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
            Standardschrift von Ihrem Computer genutzt.
          </p>
          <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter
            https://developers.google.com/fonts/faq und in der
            Datenschutzerklärung von Google:
            https://www.google.com/policies/privacy/.
          </p>
          <p>b) Google Maps</p>
          <p>
            Diese Seite nutzt über eine API den Kartendienst Google Maps.
            Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA.
          </p>
          <p>
            Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
            Adresse zu speichern. Diese Informationen werden in der Regel an
            einen Server von Google in den USA übertragen und dort gespeichert.
            Als Anbieter dieser Seite haben wir keinen Einfluss auf diese
            Datenübertragung.
          </p>
          <p>
            Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online-Angebote und an einer leichten
            Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
            stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
            DSGVO dar.
          </p>
          <p>
            Weitere Informationen zu Google Maps finden Sie in den
            Nutzungsbedingungen von Google Maps:
            https://www.google.com/intl/de_de/help/terms_maps.html und in der
            Datenschutzerklärung von Google: :
            https://www.google.de/intl/de/policies/privacy/.
          </p>
          <p className="font-weight-bold">5. Betroffenenrechte</p>
          <p>Sie haben das Recht:</p>
          <p>
            gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
            personenbezogenen Daten zu verlangen. Insbesondere können Sie
            Auskunft über die Verarbeitungszwecke, die Kategorie der
            personenbezogenen Daten, die Kategorien von Empfängern, gegenüber
            denen Ihre Daten offengelegt wurden oder werden, die geplante
            Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
            Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines
            Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei
            uns erhoben wurden, sowie über das Bestehen einer automatisierten
            Entscheidungsfindung einschließlich Profiling und ggf.
            aussagekräftigen Informationen zu deren Einzelheiten verlangen;
          </p>
          <p>
            gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
            Vervollständigung Ihrer bei uns gespeicherten personenbezogenen
            Daten zu verlangen;
          </p>
          <p>
            gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
            personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung
            zur Ausübung des Rechts auf freie Meinungsäußerung und Information,
            zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
            öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen erforderlich ist;
          </p>
          <p>
            gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen, soweit die Richtigkeit der
            Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist,
            Sie aber deren Löschung ablehnen und wir die Daten nicht mehr
            benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21
            DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
          </p>
          <p>
            gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
            bereitgestellt haben, in einem strukturierten, gängigen und
            maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
            anderen Verantwortlichen zu verlangen;
          </p>
          <p>
            gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
            jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir
            die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
            Zukunft nicht mehr fortführen dürfen und
          </p>
          <p>
            gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.
            In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres
            üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
            Kanzleisitzes wenden.
          </p>
          <p className="font-weight-bold">6. Widerspruchsrecht</p>
          <p>
            Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
            Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden,
            haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die
            Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
            Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben
            oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren
            Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe
            einer besonderen Situation von uns umgesetzt wird.
            <br />
            Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
            machen, genügt eine E-Mail an
            <a href="mailto:info@ksrechtsanwaelte.de">
              info@ksrechtsanwaelte.de
            </a>
            .
          </p>
          <p className="font-weight-bold">7. Datensicherheit</p>
          <p>
            Wir verwenden innerhalb des Website-Besuchs das verbreitete
            SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
            höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt
            wird. In der Regel handelt es sich dabei um eine 256 Bit
            Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung
            unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie
            zurück. Ob eine einzelne Seite unseres Internetauftrittes
            verschlüsselt übertragen wird, erkennen Sie an der geschlossenen
            Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der
            unteren Statusleiste Ihres Browsers.
            <br />
            Wir bedienen uns im Übrigen geeigneter technischer und
            organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
            zufällige oder vorsätzliche Manipulationen, teilweisen oder
            vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff
            Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend
            der technologischen Entwicklung fortlaufend verbessert.
          </p>
          <p className="font-weight-bold">
            8. Aktualität und Änderung dieser Datenschutzerklärung
          </p>
          <p>
            Diese Datenschutzerklärung ist aktuell gültig und hat den Stand
            2018. Durch die Weiterentwicklung unserer Website und Angebote
            darüber oder aufgrund geänderter gesetzlicher beziehungsweise
            behördlicher Vorgaben kann es notwendig werden, diese
            Datenschutzerklärung zu ändern.
          </p>
          <p>
            Wir unterliegen bei unserer beruflichen Tätigkeit den Vorschriften
            der BRAO, BORA, FAO, den CCBE‐Berufsregeln und dem RVG (siehe
            www.brak.de/fuer-anwaelte/berufsrecht).
          </p>
          <p>
            Plattform der EU zur außergerichtlichen Online‐Streitbeilegung:
            https://ec.europa.eu/consumers/odr/ Zur Teilnahme an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            erklären wir uns nicht bereit.
          </p>
          <p>
            Diese Internetseite dient ausschließlich Informationszwecken. Sie
            ist nicht dazu bestimmt, konkreten Rechtsrat zu erteilen oder ein
            Mandatsverhältnis anzubahnen. Alle Rechte an der Internetseite
            liegen bei den Urhebern. Für den Inhalt der Website wird keine
            Haftung übernommen.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default DatenschutzPage
